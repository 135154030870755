import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { mainWhite, mainBlue, screen } from "../variables";

const Wrapper = styled.section`
  .faq {
    background: ${mainBlue};
    color: ${mainWhite};
    padding: 50px 0;

    .flex {
      @media ${screen.xsmall} {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
      }

      .col {
        &--left {
          h4 {
            font-size: 1.8rem;
            text-align: center;
            line-height: 1.45;
            margin: 0 auto 21px auto;
            max-width: 310px;
            @media ${screen.xsmall} {
              font-size: 2.2rem;
              text-align: left;
              max-width: none;
              margin: 0;
            }
          }
        }

        &--right {
          .btn {
            border: 2px ${mainWhite} solid;
            color: ${mainWhite};
            text-transform: uppercase;
            font-size: 0.95rem;
            display: block;
            text-align: center;
            @media ${screen.xsmall} {
              font-size: 1rem;
              display: inline-block;
            }
            @media ${screen.withCursor} {
              &:hover {
                background: ${mainWhite};
                color: ${mainBlue};
              }
            }
          }
        }
      }
    }
  }
`;

const Faq = () => {
  return (
    <Wrapper>
      <div className="faq">
        <div
          className="inner-wrapper flex"
          data-sal="fade"
          data-sal-duration="700"
        >
          <div className="col col--left">
            <h4>
              <strong>Still got questions?</strong> We’ve got answers.
            </h4>
          </div>

          <div className="col col--right">
            <Link className="btn" to="/faqs/">
              FREQUENTLY ASKED QUESTIONS
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Faq;
