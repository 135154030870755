import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import { toSlug } from "../../helpers";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import {
  mainWhite,
  transHover,
  mainBlue,
  dirtyWhite,
  screen,
} from "../variables";
import Ad from "../../components/ad";
import Tag from "../../components/tag/thumbnail";

const Wrapper = styled.div`
  .product-list {
    display: flex;
    margin: 0 -8px;
    flex-wrap: wrap;
    @media ${screen.xsmall} {
      margin: 0 -16px;
    }
    @media ${screen.medium} {
      justify-content: ${(props) => (props.template ? "flex-start" : "center")};
    }

    &__each {
      width: calc(50% - 16px);
      background: ${mainWhite};
      margin: 0 8px 28px 8px;
      cursor: pointer;
      align-self: flex-start;
      position: relative;
      overflow: hidden;
      @media ${screen.xsmall} {
        margin: 0 16px 32px 16px;
        width: calc(33.33% - 32px);
      }
      @media ${screen.small} {
        width: calc(25% - 32px);
      }
      @media ${screen.medium} {
        width: calc(20% - 32px);
      }

      .img {
        border-bottom: 3px solid ${dirtyWhite};
        position: relative;
        @media ${screen.xsmall} {
          border-bottom: 6px solid ${dirtyWhite};
        }

        &::after {
          content: "";
          position: absolute;
          background: rgba(32, 33, 33, 0.5);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: ${transHover};
          z-index: 6;
          opacity: 0;
        }

        img {
          transition: all 0.33s ease-out;
        }
      }

      .txt {
        padding: 14px;
        position: relative;

        &::after {
          content: "LEARN MORE";
          color: ${mainWhite};
          position: absolute;
          background: ${mainBlue};
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.95rem;
          transition: ${transHover};
          opacity: 0;
        }

        .name {
          color: ${mainBlue};
          font-size: 0.84rem;
          font-weight: 500;
          line-height: 1.4;
          @media ${screen.xsmall} {
            font-size: 0.94rem;
          }
        }

        .specs {
          font-size: 0.84rem;
          margin: 6px 0 0 0;
          @media ${screen.xsmall} {
            font-size: 0.94rem;
          }
        }
      }

      .btn {
        display: block;
        background: ${mainBlue};
        color: ${mainWhite};
        white-space: pre;
        text-align: center;
        font-size: 0.84rem;
        width: 100%;
        padding: 8px 0 4px 0;
        @media ${screen.xsmall} {
          display: none;
        }
      }

      &--ad {
        display: none;
        @media ${screen.xsmall} {
          display: block;
          align-self: auto;
          cursor: auto;
        }
        .btn {
          font-size: 1rem;
          @media ${screen.withCursor} {
            &:hover {
              background: ${mainWhite};
              color: ${mainBlue};
            }
          }
        }
      }

      @media ${screen.withCursor} {
        &:hover .txt::after,
        &:hover .img::after {
          opacity: 1;
        }
        &:hover .img img {
          transform: scale(1.06);
        }
      }
    }
  }
`;

const FeaturedProductList = ({ products, template }) => {
  return (
    <Wrapper template={template}>
      <ul className="product-list">
        {products.map(
          (item, i) =>
            item.product.document && (
              <li
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay={`${i}00`}
                className="product-list__each"
                onClick={() => {
                  navigate(
                    `/products/${toSlug(item.product.document.data.title.text)}`
                  );
                }}
                key={item.product.document.id + i}
              >
                <figure className="img">
                  {item.product.document.data.gallery[0] &&
                  item.product.document.data.gallery[0].image
                    .gatsbyImageData ? (
                    <GatsbyImage
                      image={
                        item.product.document.data.gallery[0].image
                          .gatsbyImageData
                      }
                      alt={
                        item.product.document.data.gallery[0].image.alt ||
                        "Featured"
                      }
                    />
                  ) : (
                    <StaticImage
                      src="../images/fallback-gallery.jpg"
                      alt="Thumbnail"
                    />
                  )}
                </figure>
                <div className="txt">
                  <h3 className="name">
                    {item.product.document.data.title.text}
                  </h3>
                  <h3 className="specs">
                    {item.product.document.data.highlighted_specification_value}
                  </h3>
                </div>
                <button className="btn">LEARN MORE</button>
                <Tag label={item.product.document.data.tag} />
              </li>
            )
        )}
        {template && (
          <li className="product-list__each product-list__each--ad">
            <Ad />
          </li>
        )}
      </ul>
    </Wrapper>
  );
};

export default FeaturedProductList;
