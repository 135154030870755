import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainBlue,
  mainBlack,
  screen,
  dirtyWhite,
} from "../components/variables";
import Faq from "../components/faq";
import FeaturedProductList from "../components/featured-product-list";
import { StoreContext } from "../context";

const Wrapper = styled.div`
  padding: 34px 0 0 0;
  background: ${mainWhite};
  @media ${screen.small} {
    padding: 0;
  }

  .hero {
    background-image: ${(props) => `url(${props.background_image_mobile})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 0 160px 0;
    position: relative;
    overflow: hidden;
    @media ${screen.xsmall} {
      background-image: ${(props) => `url(${props.background_image})`};
      padding: 110px 0;
    }
    @media ${screen.small} {
      padding: 140px 0;
    }

    .heading {
      color: ${mainBlue};
      margin: 0 0 15px 0;
      font-size: 1.4rem;
      @media ${screen.xsmall} {
        font-size: 1.95rem;
        margin: 0 0 25px 0;
      }
      @media ${screen.small} {
        font-size: 2.15rem;
      }
    }

    .description {
      max-width: 550px;

      p {
        font-size: 0.95rem;
        margin: 0 0 20px 0;
      }

      a {
        color: ${mainBlack};
        text-decoration: underline;
      }
    }

    &::after {
      content: "";
      width: 80px;
      height: 80px;
      bottom: -40px;
      right: -40px;
      background: ${mainWhite};
      display: block;
      position: absolute;
      transform: rotate(45deg);
      @media ${screen.xsmall} {
        width: 120px;
        height: 120px;
        bottom: -60px;
        right: -60px;
      }
      @media ${screen.small} {
        width: 150px;
        height: 150px;
        bottom: -75px;
        right: -75px;
      }
    }
  }

  .info {
    padding: 55px 0;
    @media ${screen.xsmall} {
      padding: 90px 0;
    }

    .info-list {
      display: flex;
      flex-wrap: wrap;
      @media ${screen.xsmall} {
        margin: 0 -25px;
      }
      @media ${screen.small} {
        margin: 0 -50px;
      }
      @media ${screen.medium} {
        margin: 0;
      }

      &__each {
        width: 100%;
        margin: 0 0 37px 0;
        @media ${screen.xsmall} {
          width: calc(50% - 50px);
          margin: 0 25px 50px 25px;
        }
        @media ${screen.small} {
          width: calc(50% - 100px);
          margin: 0 50px 50px 50px;
        }
        @media ${screen.medium} {
          padding: 0 120px 0 0;
          margin: 0 0 60px 0;
          width: 50%;
        }

        .icon {
          width: 82px;
          height: 82px;
          @media ${screen.small} {
            width: 92px;
            height: 92px;
          }
        }

        .heading {
          font-size: 1.3rem;
          font-weight: 500;
          color: ${mainBlue};
          margin: 12px 0;
          @media ${screen.xsmall} {
            font-size: 1.35rem;
          }
          @media ${screen.small} {
            font-size: 1.55rem;
          }
          @media ${screen.medium} {
            font-size: 1.65rem;
          }
        }

        .description {
          p {
            font-size: 0.95rem;
            margin: 0 0 20px 0;
          }
        }

        &--cta {
          background-image: ${(props) => `url(${props.cta_background_image})`};
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          padding: 25px 0 45px 0;
          position: relative;
          @media ${screen.xsmall} {
            padding: 25px 25px;
          }
          @media ${screen.small} {
            padding: 30px 30px;
          }
          @media ${screen.medium} {
            padding: 40px 50px;
          }

          .heading {
            color: ${mainBlack};
            font-weight: 400;
            max-width: 190px;
            line-height: 1.4;
            @media ${screen.xsmall} {
              max-width: 330px;
              margin: 0 0 24px 0;
            }
          }

          .btn {
            background: ${mainBlue};
            border: 2px ${mainBlue} solid;
            color: ${mainWhite};
            position: absolute;
            width: calc(100% - 50px);
            left: 50%;
            bottom: -35px;
            transform: translateX(-50%);
            text-align: center;
            @media ${screen.xsmall} {
              position: static;
              width: auto;
              left: auto;
              bottom: auto;
              transform: translateX(0);
            }
            @media ${screen.withCursor} {
              &:hover {
                background: none;
                color: ${mainBlue};
              }
            }
          }
        }
      }
    }
  }

  .customise {
    background-image: ${(props) =>
      `url(${props.customise_background_image_mobile})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    padding: 50px 0 360px 0;
    position: relative;
    overflow: hidden;
    color: ${mainWhite};
    margin: 0 0 90px 0;
    @media ${screen.xsmall} {
      background-image: ${(props) =>
        `url(${props.customise_background_image})`};
      padding: 70px 0;
      background-position: center;
    }
    @media ${screen.small} {
      padding: 90px 0;
    }

    .txt-wrapper {
      max-width: 600px;
      margin: 0 0 0 auto;

      .heading {
        margin: 0 0 15px 0;
        font-size: 1.4rem;
        @media ${screen.xsmall} {
          font-size: 1.95rem;
          margin: 0 0 25px 0;
        }
        @media ${screen.small} {
          font-size: 2.15rem;
        }
      }

      .description {
        p {
          font-size: 0.95rem;
          margin: 0 0 20px 0;
          &:last-child {
            margin: 0;
          }
        }
      }

      .btn {
        border: 2px ${mainWhite} solid;
        color: ${mainWhite};
        margin: 30px 0 0 0;
        display: block;
        text-align: center;
        @media ${screen.xsmall} {
          display: inline-block;
        }
        @media ${screen.withCursor} {
          &:hover {
            background: ${mainWhite};
            color: ${mainBlue};
          }
        }
      }
    }

    &::after {
      @media ${screen.xsmall} {
        content: "";
        width: 120px;
        height: 120px;
        bottom: -60px;
        right: -60px;
        background: ${mainWhite};
        display: block;
        position: absolute;
        transform: rotate(45deg);
      }
      @media ${screen.small} {
        width: 150px;
        height: 150px;
        bottom: -75px;
        right: -75px;
      }
    }
  }

  .featured-product {
    background: ${dirtyWhite};
    padding: 70px 0 60px 0;
    @media ${screen.xsmall} {
      padding: 120px 0;
    }

    .heading {
      color: ${mainBlue};
      margin: 0 0 25px 0;
      font-size: 1.4rem;
      @media ${screen.xsmall} {
        font-size: 1.95rem;
        margin: 0 0 50px 0;
        text-align: center;
      }
      @media ${screen.small} {
        font-size: 2.15rem;
      }
    }
  }
`;

const AboutPage = ({ data }) => {
  const {
    title_tag,
    meta_description,
    title,
    banner_background_image,
    background_image_mobile,
    banner_description,
    cta_background_image,
    cta_heading,
    customise_background_image,
    customise_background_image_mobile,
    customise_description,
    customise_heading,
    featured_products,
    item_list,
  } = data.content.data;

  const { setIsFormPackaging } = useContext(StoreContext);

  return (
    <Layout>
      <Seo title={title_tag || "Home"} description={meta_description} />

      <Wrapper
        customise_background_image={customise_background_image.url}
        customise_background_image_mobile={
          customise_background_image_mobile.url
        }
        cta_background_image={cta_background_image.url}
        background_image={banner_background_image.url}
        background_image_mobile={background_image_mobile.url}
      >
        <header className="hero">
          <div
            className="inner-wrapper flex"
            data-sal="fade"
            data-sal-duration="500"
          >
            <h1 className="heading">{title.text}</h1>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: banner_description.html }}
            />
          </div>
        </header>
        <div className="info">
          <div className="inner-wrapper">
            <ul className="info-list">
              {item_list.map((item, i) => (
                <li
                  className="info-list__each"
                  key={item.heading.text}
                  data-sal="slide-right"
                  data-sal-duration="700"
                  data-sal-delay={`${i}00`}
                >
                  <img
                    className="icon"
                    src={item.icon.url}
                    alt={item.alt || "Icon"}
                  />
                  <h3 className="heading">{item.heading.text}</h3>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: item.description.html }}
                  />
                </li>
              ))}

              <li
                className="info-list__each info-list__each--cta"
                data-sal="fade"
                data-sal-duration="900"
                data-sal-delay="400"
              >
                <h4 className="heading">{cta_heading.text}</h4>
                <Link to="/products/" className="btn">
                  VIEW PRODUCTS
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <section className="customise">
          <div className="inner-wrapper">
            <div
              className="txt-wrapper"
              data-sal="slide-left"
              data-sal-duration="700"
            >
              <h2 className="heading">{customise_heading.text}</h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: customise_description.html }}
              />

              <Link
                className="btn"
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  setIsFormPackaging(true);
                }}
              >
                ENQUIRE
              </Link>
            </div>
          </div>
        </section>

        <Faq />

        <section className="featured-product">
          <div className="inner-wrapper">
            <h3 className="heading">Featured Products</h3>
            <FeaturedProductList products={featured_products} />
          </div>
        </section>
      </Wrapper>
    </Layout>
  );
};

export default AboutPage;

export const dataQuery = graphql`
  {
    content: prismicAboutPage {
      data {
        banner_background_image {
          url
        }
        background_image_mobile {
          url
        }
        banner_description {
          html
        }
        cta_background_image {
          url
        }
        cta_heading {
          text
        }
        customise_background_image {
          url
        }
        customise_background_image_mobile {
          url
        }
        customise_description {
          html
        }
        customise_heading {
          text
        }
        featured_products {
          product {
            id
            document {
              ... on PrismicProduct {
                id
                data {
                  title {
                    text
                  }
                  tag
                  gallery {
                    image {
                      gatsbyImageData(layout: FULL_WIDTH)
                      alt
                    }
                  }
                  highlighted_specification_value
                }
              }
            }
          }
        }
        item_list {
          description {
            html
          }
          heading {
            text
          }
          icon {
            alt
            url
          }
        }
        meta_description
        title_tag
        title {
          text
        }
      }
    }
  }
`;
